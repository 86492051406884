import { Nullable } from '@models/nullable.model';

// <-- Transform Date -->

export const toUTC = (date: Date, timezone?: number): Date => {
  const newDate = new Date();
  newDate.setTime(
    date.getTime() + (timezone ?? date.getTimezoneOffset()) * 60 * 1000,
  );
  return newDate;
};

export const dateToISOFormat = (date?: Nullable<Date>): Nullable<string> => {
  if (!date) return null;
  return date.toISOString().split('T')[0];
};

export const stringToDate = (date?: string): Nullable<Date> => {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  if (!year || !month || !day) return null;
  return new Date(Date.UTC(+year, +month - 1, +day));
};

export const dateToString = (
  year: number,
  month: number,
  day: number,
): Nullable<string> => {
  if (month > 12 || month < 0 || day < 0 || day > 31) return null;
  const newMonth = month + 1;
  return `${year}-${newMonth < 10 ? '0' + newMonth : newMonth}-${
    day < 10 ? '0' + day : day
  }`;
};

// <-- Get Date -->

export function getMaxDate<T>(value: T[]): Nullable<T> {
  return value.reduce((acc: Nullable<T>, e) => {
    if (!acc) {
      return e;
    }

    return e > acc ? e : acc;
  }, null);
}

export function getMinDate<T>(value: T[]): Nullable<T> {
  return value.reduce((acc: Nullable<T>, e) => {
    if (!acc) {
      return e;
    }
    if (!e) {
      return acc;
    }

    return e < acc ? e : acc;
  }, null);
}

function getPositionDate(
  dates: Date[],
  value: Date,
  options: {
    position: 'next' | 'prev';
    condition?: boolean;
  } = { position: 'next' },
): Date | undefined {
  const { condition = false, position } = options;
  if (!value || condition) {
    return;
  }

  const indexOf = dates.findIndex(
    (date) => date.toDateString() === value.toDateString(),
  );

  if (indexOf === -1) {
    return;
  }

  if (indexOf === 0) {
    return dates.at(indexOf);
  }

  const i = position === 'next' ? indexOf + 1 : indexOf - 1;
  return dates.at(i);
}

export function getPreviousDate(
  dates: Date[],
  value: Date,
  options: { condition?: boolean } = {},
): Date | undefined {
  const { condition = false } = options;

  return getPositionDate(dates, value, { position: 'prev', condition });
}

export function getNextDate(
  dates: Date[],
  value: Date,
  options: { condition?: boolean } = {},
): Date | undefined {
  const { condition = false } = options;

  return getPositionDate(dates, value, { position: 'next', condition });
}

export const getClosestDate = (
  selectedDate: Nullable<string>,
  dates: string[],
): Nullable<string> => {
  try {
    if (!selectedDate) {
      return null;
    }

    const i = dates.findIndex((d) => d >= selectedDate);
    if (i === -1) {
      if (dates.length) {
        return dates[dates.length - 1];
      }
      return null;
    }
    if (i === 0) return dates[i];

    const date1 = dates[i - 1];
    const date2 = dates[i];

    const distanceDate1 = Math.abs(
      new Date(selectedDate).getTime() - new Date(date1).getTime(),
    );
    const distanceDate2 = Math.abs(
      new Date(selectedDate).getTime() - new Date(date2).getTime(),
    );
    return distanceDate1 < distanceDate2 ? date1 : date2;
  } catch {
    return null;
  }
};

// < -- Sort Dates -- >

export function sortDatesAsc(a: string, b: string): number {
  const dateA = toUTC(new Date(a)).getTime();
  const dateB = toUTC(new Date(b)).getTime();

  return dateA - dateB;
}

export function sortDatesDes(a: string, b: string): number {
  const dateA = toUTC(new Date(a)).getTime();
  const dateB = toUTC(new Date(b)).getTime();

  return dateB - dateA;
}

export const isDateExpired = (
  actualDate: number,
  date: Nullable<number>,
): boolean => {
  return !!date && date < Math.ceil(actualDate.valueOf() / 1000);
};
